import * as React from "react"
import { Header, BlogPostOpen, Footer, BottomNavbar } from "../components/index"
import { Helmet } from "react-helmet"

import mockup from "../utils/data"

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Esteekey - Post Opened</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        <BlogPostOpen
          title={mockup.blog_post.title}
          overview={mockup.blog_post.overview}
          publish={mockup.blog_post.publish}
          url={mockup.blog_post.url}
          list={mockup.blog_post.recent_post}
          image={mockup.blog_post.image}
          content={mockup.blog_post.content}
        />
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export default IndexPage
